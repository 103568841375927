<template>
  <validation-observer
    ref="infoRules"
    v-slot="{ invalid }"
    tag="form"
  >
    <b-modal
      id="select-product"
      ref="select-product"
      hide-footer
      centered
      size="xl"
      title="Select Product"
      no-close-on-backdrop
    >

      <b-col
        cols="12"
      >
        <div>
          <b-tabs>
            <b-tab
              title="All Products"
              active
            >
              <general-table
                ref="selectedProductsTable"
                :api-url="apiUrl"
                :view-content="false"
                :delete-content="false"
                :block-content="false"
                :type="type"
                :columns="columns"
                :selectable="false"
                :per-page="perPage"
              >
                <template #filter>
                  <b-col
                    v-if="$route.params.id!==undefined"
                    cols="12"
                    md="4"
                    lg="3"
                  >
                    <b-input-group>
                      <b-form-select
                        v-model="filter.product_gift_id"
                        value-field="value"
                        text-field="name"
                        :options="campaign"
                      />

                      <b-input-group-append v-if="filter.product_gift_id">
                        <b-button
                          size="sm"
                          @click="filter.product_gift_id = ''"
                        >
                          <span class="lead">
                            &times;
                          </span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-button
                    v-if="$route.params.id!==undefined"

                    variant="primary"
                    :disabled="Object.values(filter).length < 1"
                    @click="filterProducts"
                  >
                    Filter
                  </b-button>
                </template>
                <template #cell(selects)="data">

                  <span>
                    <b-form-checkbox
                      :checked="isSelected(data.item.id)"
                      class="custom-control-primary"
                      @change="onRowSelected(data.item)"
                    />
                  </span>
                </template>

                <template #cell(price)="data">
                  <span :class="data.item.offer > 0 ? 'discounted-price' : ''">

                    {{
                      data.item.price
                    }}</span>
                  <sup
                    v-if="data.item.offer > 0"
                    class="conOfOffer"
                  >-{{ data.item.offer }}%</sup>
                  <span
                    v-if="data.item.offer> 0"
                    class="ml-1"
                  >{{ data.item.net_price }}</span>
                </template>
                <template #cell(num_of_tickets)="data">

                  <validation-provider
                    #default="validationContext"
                    :name="`tickets_${data.item.id}`"

                    :rules="getValidationRules(data.item.id)"
                  >

                    <b-form-input
                      id="tickets"
                      v-model="selectedProductsWithTickets[data.item.id]"
                      type="number"
                      :disabled="!isSelected(data.item.id)"
                      trim
                      :state="getValidationState(validationContext)"
                      placeholder="Number of free tickets"
                      @input="updateProductNumOfTicketsStore(data.item.id, selectedProductsWithTickets[data.item.id])"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </template>
              </general-table>
            </b-tab>
            <b-tab
              title="Current Selection"
              @click="getLocalSelectedProducts"
            >
              <b-card>
                <b-table
                  ref="productsTable"
                  key="2"
                  :items="itemsFromComponent"
                  :fields="columns"
                  aria-busy="true"
                  class="position-relative"
                  responsive
                  show-empty
                  bordered
                  primary-key="id"
                  @row-selected="onRowSelected"
                >

                  <template #cell(selects)="data">

                    <div class="d-flex">
                      <b-form-checkbox
                        :checked="isSelected(data.item.id)"
                        class="custom-control-primary"
                        @change="onRowSelected(data.item)"
                      />
                      <b-badge
                        v-if="!data.item.active"
                        variant="success"
                      >
                        Saved
                      </b-badge>
                    </div>
                  </template>
                  <template #cell(product_images)="data">

                    <b-img
                      v-if="data.item.product_images[0].url!==undefined"
                      v-img
                      width="70"
                      :src="data.item.product_images[0].url"
                    />
                    <b-img
                      v-else
                      v-img
                      width="70"
                      :src="data.item.product_images"
                    /></template>
                  <template #cell(price)="data">
                    <span :class="data.item.offer > 0 ? 'discounted-price' : ''">

                      {{
                        data.item.price
                      }}</span>
                    <sup
                      v-if="data.item.offer > 0"
                      class="conOfOffer"
                    >-{{ data.item.offer }}%</sup>
                    <span
                      v-if="data.item.offer> 0"
                      class="ml-1"
                    >{{ data.item.net_price }}</span>
                  </template>
                  <template #cell(num_of_tickets)="data">

                    <validation-provider
                      #default="validationContext"
                      :name="`tickets_${data.item.id}`"
                      :rules="getValidationRules(data.item.id)"
                    >
                      <b-form-input
                        id="currentTickets"
                        v-model="selectedProductsWithTickets[data.item.id]"
                        type="number"
                        :disabled="!isSelected(data.item.id)"
                        trim
                        :state="getValidationState(validationContext)"
                        placeholder="Number of free tickets"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>

                  </template>

                </b-table>

                <div class="d-flex align-items-center justify-content-end">
                  <div :class="getCurrentPage==1?'conOfPaginationGrey':'conOfPagination'">

                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      @click="prevPaginatedPage"
                    />
                  </div>
                  <span>{{ getCurrentPage }}</span>
                  <div :class="getCurrentPage==totalPages?'conOfPaginationGrey':'conOfPagination'">
                    <feather-icon

                      icon="ChevronRightIcon"
                      size="18"
                      @click="nextPaginatedPage"
                    />
                  </div>
                </div>

              </b-card>

            </b-tab>

          </b-tabs>
        </div>
      </b-col>

      <b-col class="mb-2">
        <small class="text-danger ">Note : Selected Products without providing number of tickets Will not be included in the campaign</small>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-button
          variant="primary"
          class="mr-1"
          :disabled="invalid"

          @click.prevent="selectProducts()"
        >
          Select
        </b-button>
        <b-button
          variant="outline-danger"
          class="closeModal"
          @click="closeProductsModal()"
        >
          Cancel
        </b-button>

      </b-col>
    </b-modal>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { mapGetters, mapActions } from 'vuex'
import { extend } from 'vee-validate'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import eventBus from '@/EventBus'

extend('minValue', {
  validate: value => value > 0,
  message: 'Please enter number greater than 0.',
})
export default {
  components: {
    GeneralTable,
  },
  data() {
    return {
      selectedRows: [], // Array to store selected rows
      APIURL: 'admin/products?active=1',
      viewContent: true,
      type: 'page',
      errors: {},
      filter: {
      },
      validationErrors: {},
      selectedProductsWithTickets: [],
      perPage: 5,
      itemsFromComponent: [],
      campaign: [
        {
          name: 'Saved Selected Products',
          value: this.$route.params.id,
        },
      ],
      enableCurrentSelection: false,
      columns: [
        { key: 'selects' },
        { key: 'product_images', label: 'Image' },
        { key: 'name', label: 'Name' },
        { key: 'price', label: 'Price' },
        { key: 'qty', label: 'Quantity' },
        { key: 'num_of_tickets', label: 'No. Of Free Tickets' },
      ],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    return {
      getValidationState,

    }
  },
  computed: {
    ...mapGetters({
      getSelectedRows: 'selectedProducts/getSelectedRows', paginatedSelectedRows: 'selectedProducts/paginatedSelectedRows', getCurrentPage: 'selectedProducts/getCurrentPage', totalPages: 'selectedProducts/totalPages',
    }),
    hasNumOfTicketsErrors() {
      return this.validationErrors
    },
    apiUrl() {
      let base = 'admin/products?active=1&'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },

  mounted() {
    // Listen for the event to open the modal
    eventBus.$on('open-select-product-modal', this.openModal)
    if (!this.$route.params.id) {
      this.$store.commit('selectedProducts/clearSelectedRows')
    }
  },

  methods: {
    getValidationRules(itemId) {
      return this.isSelected(itemId) ? 'minValue|required' : ''
    },
    updateValidationErrors(productId, errors) {
      // Ensure validationErrors has an entry for the current productId
      if (!this.validationErrors[productId]) {
        this.$set(this.validationErrors, productId, [])
      }

      // Update the errors for the current productId
      this.validationErrors[productId] = [...errors] // Create a new array

      // Check if there are errors, if not, clear the validationErrors for the current productId after the next DOM update
      if (this.validationErrors[productId].length === 0) {
        this.$nextTick(() => {
          this.$delete(this.validationErrors, productId)
        })
      }
    },

    nextPaginatedPage() {
      if (this.getCurrentPage < this.totalPages) {
        this.setCurrentPage(this.getCurrentPage + 1)
        this.getLocalSelectedProducts()
      }
    },
    prevPaginatedPage() {
      if (this.getCurrentPage !== 1) {
        this.setCurrentPage(this.getCurrentPage - 1)
        this.getLocalSelectedProducts()
      }
    },
    updateProductNumOfTicketsStore(selectedRowId, numOfTickets) {
      this.$store.dispatch('selectedProducts/updateProductNumOfTickets', {
        selectedRowId,
        numOfTickets,
      })
    },
    filterProducts() {
      this.itemsFromComponent = []
      this.$refs.selectedProductsTable.getAllData()
    },
    openModal() {
      this.selectedProductsWithTickets = this.getSelectedRows.reduce((result, obj) => ({ ...result, [obj.id]: obj.num_of_tickets }), {})

      this.$bvModal.show('select-product')
    },
    getLocalSelectedProducts() {
      this.itemsFromComponent = this.paginatedSelectedRows
      if (this.itemsFromComponent.length > 0) {
        this.enableCurrentSelection = true
        this.selectedProductsWithTickets = this.paginatedSelectedRows.reduce((result, obj) => ({ ...result, [obj.id]: obj.num_of_tickets }), {})
      }
    },
    closeProductsModal() {
      this.clearSelectedRows()
      this.$router.replace({ query: {} })
      this.selectedProductsWithTickets = this.getSelectedRows.reduce((result, obj) => ({ ...result, [obj.id]: obj.num_of_tickets }), {})
      this.$bvModal.hide('select-product')
    },
    ...mapActions('selectedProducts', ['updateSelectedRows', 'updateProductNumOfTickets', 'removeSelectedRow', 'saveSelectedRowCopy', 'clearAllSelectedRows', 'setCurrentPage']), // Map the updateProductNumOfTickets action
    getSelectedProductsWithTicketsLength(obj) {
      return Object.keys(obj).length
    },
    selectProducts() {
      this.removeRowsWithoutTickets()
      this.saveSelectedRowCopy()
      this.$router.replace({ query: {} })
      this.$bvModal.hide('select-product')
    },
    onRowSelected(row) {
      if (this.isSelected(row.id)) {
        this.removeSelectedRow(row)
        this.$delete(this.selectedProductsWithTickets, row.id)
      } else {
        const numberOfTickets = this.selectedProductsWithTickets[row.id]
        this.updateSelectedRows([row])
        // Assuming ticketQuantities is an object containing ticket quantities
        if (numberOfTickets) {
          // Assuming selectedProductsWithTickets is an object to store selected products with tickets
          this.$set(this.selectedProductsWithTickets, row.id, numberOfTickets)
          this.$set(row, 'num_of_tickets', numberOfTickets)
        } else {
          this.$set(this.selectedProductsWithTickets, row.id, 1)
          this.$set(row, 'num_of_tickets', 1)
        }
      }
    },

    isSaveProductsDisabled() {
      // Check if any selected row doesn't have a number of tickets
      return Object.keys(this.selectedProductsWithTickets).some(
        id => this.isSelected(id) && !this.selectedProductsWithTickets[id],
      )
    },
    removeRowsWithoutTickets() {
      const selectedRows = this.getSelectedRows

      selectedRows.forEach(selectedRow => {
        const { id } = selectedRow

        // Check if the corresponding ticketQuantities don't have a number of tickets
        if (this.selectedProductsWithTickets[id] === undefined || this.selectedProductsWithTickets[id] === null || this.selectedProductsWithTickets[id] === '') {
          // Remove the row from selectedProducts
          this.$store.dispatch('selectedProducts/removeSelectedRow', selectedRow)

          this.$delete(this.selectedProductsWithTickets, selectedRow.id)
        } else {
          const numberOfTickets = this.selectedProductsWithTickets[selectedRow.id]

          this.$set(selectedRow, 'num_of_tickets', numberOfTickets)
          this.updateSelectedRows([selectedRow])
        }
      })
    },
    isSelected(row) {
      const selectedRows = this.getSelectedRows

      return selectedRows && selectedRows.some(selectedRow => selectedRow.id === row)
    },
    clearSelectedRows() {
      this.selectedProductsWithTickets = {}
      this.clearAllSelectedRows()
    },
  },
}
</script>

   <style scoped>
   h1 {
    color: blue;
   }

   p {
    color: red;
   }
   .conOfPagination{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f48a36;
     margin: 8px;
    width: 28px;
    height: 28px;
    color: #fff;
    font-size: 12px;
   }
   .conOfPaginationGrey{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #c5c4c4;
     margin: 8px;
    width: 28px;
    height: 28px;
    color: #aeaeae;
    font-size: 12px;
   }
   </style>
